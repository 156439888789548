import DefaultLayout from "layouts/Default";
import LoginForm from "components/forms/LoginForm";
import Wrapper from "components/blocks/Wrapper";
import Container from "components/blocks/Container";
import {useRouter} from "next/router";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {wrapper} from "lib/store";
import {useTranslation} from "next-i18next";

export const getServerSideProps = wrapper.getServerSideProps(store => async (context) => {
  const {
    locale
  } = context

  return {
    props: {
      ...(await serverSideTranslations(locale, ['home', 'common']))
    }
  }
})

export default function Login() {
  const router = useRouter()
  const { t, i18n } = useTranslation()

  const handleLoginSuccess = () => {
    router.replace(router.query.return_path || '/')
  }

  return (
      <Wrapper className={'py-36'}>
        <Container className={'max-w-sm m-auto'}>
          <h2 className={'text-3xl font-bold text-center mb-8'}>
            {t('home:login')}
          </h2>
          <LoginForm onSuccess={handleLoginSuccess}/>
        </Container>
      </Wrapper>
  )
}

Login.getLayout = function getLayout(page) {
  return (
      <DefaultLayout>
        {page}
      </DefaultLayout>
  )
}
